<script>
import Layout from "@/router/website-template/main";
import appConfig from "@/app.config";
// import PageHeader from "@/components/page-header";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import { debounce } from "@/helpers/debounce";


/**
 * Skema Sertifikasi Component
 */
export default {
    page: {
        title: "Skema Sertifikasi",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        Pagination,
    },
    data() {
        return {
            title: "Skema Sertifikasi",
            items: [
                {
                    text: "Beranda",
                    href: "/",
                },
                {
                    text: "Skema Sertifikasi",
                    active: true,
                },
            ],
            kategoriSkema: null,
            banner_full: null,
            SubCategorySkema: null,
            skemaSerifikasi: null,
            randomSkema: null,
            activeSubTab: null, // Keeps track of the active sub-tab
            nextPage: null,
            id: "99caddd3-e90c-43a4-8313-ff1647587c72",
            table_dataSkema: [],
            table_dataUnit: [],
            sortKey: "nama_skema_sertifikasi", // set default column
            currentTablePage: "",
            showModal: false,
            showModalUnit: false,

            // parsing data ke table data
            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "desc",
            },

            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },
            JumlahPelaksaanAsesmen: null,
        };
    },
    created() {
    this.searchDatatable = debounce(() => {
      this.getSkema();
      // call fetch API to get results
    }, 500);
  },
    mounted() {
        if (localStorage.getItem("reloaded")) {
            // The page was just reloaded. Clear the value from local storage
            // so that it will reload the next time this page is visited.
            localStorage.removeItem("reloaded");
        } else {
            // Set a flag so that we know not to reload the page twice.
            localStorage.setItem("reloaded", "1");
            location.reload();
        }
        // let self = this;
        this.getDataHalaman();
        this.getSkema();
    },
    methods: {
        getDataHalaman() {
            let self = this;
            // get data halaman berdasarkan id
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-website/" + self.id,
                params: {},
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        var data_edit = response_data_fix;
                        self.nama_halaman = data_edit.nama_halaman;
                        self.banner_path = data_edit.banner;
                        self.banner_full = process.env.VUE_APP_BACKEND_URL + data_edit.banner;

                        // META CONTENT
                        if (data_edit.meta_content) {
                            var json_response_meta_content = JSON.parse(data_edit.meta_content);
                            let clear_data_json_response_meta_content = [];
                            $.each(json_response_meta_content, function (indexInArray, valueOfElement) {
                                clear_data_json_response_meta_content.push({
                                    judul: valueOfElement.judul,
                                    banner: valueOfElement.banner,
                                    deskripsi: valueOfElement.deskripsi,
                                });
                            });
                            self.meta_content = clear_data_json_response_meta_content;
                            self.meta_content_db = self.meta_content.length;
                        }

                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },

        getSkema(url = process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-informasi/jumlah-skema") {
            let self = this;
            self.loadingTable = true;
            self.tableData.draw++;

            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    if (response_data.meta.code == 200) {
                        self.table_dataSkema = response_data.data.list;
                        console.log(self.table_dataSkema);
                        self.configPagination(response_data.data.pagination);
                        self.loadingTable = false;
                        self.currentTablePage = response_data.data.pagination.current_page;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data.data.message,
                        });
                    }

                    console.log(response.data.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getKategoriSkema() {
            let config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-skema/skema-sertifikasi-kategori",
                params: {},
                headers: {
                    Accept: "application/json",
                },
            };
            axios(config)
                .then((response) => {
                    this.kategoriSkema = response.data.data.list;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        getSubCategory(id) {
            let config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-skema/skema-sertifikasi-subkategori/" + id,
                params: { id: id },
                headers: {
                    Accept: "application/json",
                },
            };
            axios(config)
                .then((response) => {
                    this.SubCategorySkema = response.data.data;
                    this.getSkema(this.SubCategorySkema[0].id_skema_sertifikasi_kategori);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // getSkema( subKategoriId ){
        //     console.log(subKategoriId);
        //     let config = {
        //         method: "get",
        //         url: process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-skema/skema-sertifikasi",
        //         params: {subKategori : subKategoriId},
        //         headers: {
        //             Accept: "application/json",
        //         },
        //     };
        //     axios(config)
        //         .then((response) => {
        //             this.skemaSerifikasi = response.data.data.list;
        //             console.log(this.skemaSerifikasi);
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         });
        //     },

        handleSubTabActivation(subTab) {
            console.log(subTab.id_skema_sertifikasi_kategori_sub);
            this.activeSubTab = subTab; // Update the active sub-tab
            this.getSkema(this.activeSubTab.id_skema_sertifikasi_kategori_sub); // Call the getSkema function
        },
        toPage: function (str) {
            let self = this;
            self.getSkema(str);
        },
        configPagination(data) {
            let self = this;
            self.pagination.lastPage = data.last_page;
            self.pagination.currentPage = data.current_page;
            self.pagination.total = data.total;
            self.pagination.lastPageUrl = data.last_page_url;
            self.pagination.nextPageUrl = data.next_page_url;
            self.pagination.prevPageUrl = data.prev_page_url;
            self.pagination.from = data.from;
            self.pagination.to = data.to;
            self.pagination.links = data.links;
        },
        modalUnitKompetensi(id) {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            this.showModalUnit = true;
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "halaman-skema/get-unit-by-skema", {
                    params: {
                        skema_id: id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data.data;
                    self.table_dataUnit = res_fix.referensi;
                    console.log(self.table_dataUnit);
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
    },
};
</script>

<template>
    <Layout>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="bg-img" v-bind:style="{ 'background-image': 'url(' + banner_full + ')', height: '75vh' }">
                        <div class="layer-light">
                            <h1 class="middle-center text-center text-white">SKEMA SERTIFIKASI</h1>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <div class="row mt-5">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="text-center my-5">
                            <h1>SKEMA SERTIFIKASI</h1>
                        </div>
                        <div class="row">
                            <div class="col-md-8 mx-auto">
                                <div class="row mb-4">
                                    <div class="col-md-4">
                                        <table>
                                            <tr>
                                                <td>Show</td>
                                                <td>
                                                    <select class="form-control form-control-sm text-center" id="showpaginatetable" style="width: 100%" v-model="tableData.length" @change="getSkema()">
                                                        <option value="10" selected>10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="200">200</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </td>
                                                <td>Entries</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-5">&nbsp;</div>
                                    <div class="col-md-3">
                                        <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..." v-model="tableData.search" @input="searchDatatable()" style="border-radius: 25px" />
                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-hover mb-0 table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="text-center">No</th>
                                                <th scope="col">Kode Skema</th>
                                                <th scope="col">Nama Skema sertifikasi</th>
                                                <th scope="col" class="text-center">#</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in table_dataSkema" :key="index">
                                                <th scope="row" class="text-center">{{ index + 1 }}</th>
                                                <td class="">{{ item.kode_skema_sertifikasi }}</td>
                                                <td class="">{{ item.nama_skema_sertifikasi }}</td>
                                                <td class="text-center">
                                                    <button href="" class="btn btn-primary btn-sm w-xs" @click="modalUnitKompetensi(item.id_skema_sertifikasi)">Lihat Unit Kompetensi</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <pagination :pagination="pagination" @to-page="toPage"></pagination>

                                <!-- <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                                    <b-tab active>
                                        <template v-slot:title>
                                            <span class="d-inline-block d-sm-none">
                                                <i class="fas fa-home"></i>
                                            </span>
                                            <span class="d-none d-sm-inline-block">ALL</span>
                                        </template>
                                        <div class="row mt-5">
                                            <div class="col-md-3" v-for="(itemRandom, index) in randomSkema" :key="index">
                                                <div class="card">
                                                    <div class="card-body p-2">
                                                        <div class="overlay-hover">
                                                            <img src="/website/biological-product.jpg" class="img-thumbnail" alt 
                                                                    style="width: 100%;" />
                                                            <div class="overlay">
                                                                <div class="text">
                                                                    <p class="font-15">{{ itemRandom.nama_skema_sertifikasi }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab v-for="(kategori, index) of kategoriSkema" :key="index" @click="getSubCategory(kategori.id_skema_sertifikasi_kategori)">
                                        <template v-slot:title >
                                            <span class="d-inline-block d-sm-none">
                                                <i class="far fa-envelope"></i>
                                            </span>
                                            <span class="d-none d-sm-inline-block" >{{ kategori.nama_skema_sertifikasi_kategori }}</span>
                                        </template>
                                        <b-tabs v-if="SubCategorySkema" justified nav-class="nav-tabs-custom" content-class="p-3 text-muted" >
                                                <b-tab v-for="(subTab, subIndex) in SubCategorySkema" :key="subIndex" @click="handleSubTabActivation(subTab)">
                                                <template v-slot:title>
                                                    <span class="d-inline-block d-sm-none">
                                                        <i class="far fa-envelope"></i>
                                                    </span>
                                                    <span>{{ subTab.nama_skema_sertifikasi_kategori_sub }}</span>
                                                </template>
                                             
                                                <div class="row mt-5">
                                                    <div class="col-md-3" v-for="(item, index) in skemaSerifikasi" :key="index">
                                                        <div class="card">
                                                            <div class="card-body p-2">
                                                                <div class="overlay-hover">
                                                                    <img src="/website/biological-product.jpg" class="img-thumbnail" alt 
                                                                    style="width: 100%;" />
                                                                    <div class="overlay">
                                                                        <div class="text">
                                                                            <p class="font-15">{{ item.nama_skema_sertifikasi }}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-tab>
                                        </b-tabs>
                                    </b-tab>
                                </b-tabs> -->
                            </div>
                        </div>

                        <!-- Line with Data Labels chart -->
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <b-modal v-model="showModalUnit" title="Unit Kompetensi" title-class="text-black font-18" body-class="p-3" size="lg" hide-footer>
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-hover mb-0 table-bordered table-striped">
                        <thead>
                            <tr>
                                <th scope="col" class="text-center">No</th>
                                <th scope="col">Kode Unit Kompetensi</th>
                                <th scope="col">Nama Unit Kompetensi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in table_dataUnit" :key="index">
                                <th scope="row" class="text-center">{{ index + 1 }}</th>
                                <td class="">{{ item.unit_kom_kode }}</td>
                                <td class="">{{ item.unit_kom_nama }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </b-modal>
    </Layout>
</template>

<style scoped>
.font-15 {
    font-size: 15px;
}
.blurred {
    backdrop-filter: blur(8px); /* Adjust the blur intensity as needed */
    pointer-events: none; /* This prevents interaction with the blurred background */
}
</style>
